export default function loader(nodeListSelector, configs) {
	return configs.reduce((found, config) => {
		const result = nodeListSelector(config)
		if (result === true || (!!result && result.length >= 1)) {
			if (typeof config.check !== 'function' || config.check() === true) {
				const promise = config.loader().then(({ default: mod }) => {
					config.mod = mod
					return config
				})
				return [...found, promise]
			}
		}
		return found
	}, [])
}
