import * as Sentry from '@sentry/browser'
import metaTagContent from 'js/base/meta_tag_content'

const ENV = metaTagContent('env')
const RELEASE = metaTagContent('release')

Sentry.init({
	dsn: 'https://37489870519745949b63d96bde99ba78@sentry.io/1552838',
	debug: ENV == 'dev',
	release: RELEASE,
	environment: ENV,
	attachStacktrace: true,
})

export default Sentry
