export default function metaTagContent(name) {
	const tag = document.querySelector(`meta[name='${name}']`)
	if (tag === null || typeof tag.getAttribute !== 'function') {
		throw new Error(`Cannot find <meta> tag of '${name}'`)
	}
	const content = tag.getAttribute('content')
	if (content === null || content === undefined || content === '') {
		throw new Error(`Invalid content of '${content}' for <meta> tag of '${name}'`)
	}
	return content
}
