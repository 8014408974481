import loader from 'js/loader'
import metaTagContent from 'js/base/meta_tag_content'

const HOOKS = [
	{
		key: 'Filepicker',
		loader: () => import('js/liveview/filepicker'),
	},
]

if (document.querySelectorAll('[phx-hook]').length >= 1) {
	const found = loader(
		(config) => window.document.querySelectorAll(`[phx-hook="${config.key}"]`),
		HOOKS,
	)

	Promise.all([import('phoenix'), import('phoenix_live_view')]).then((mods) => {
		const [Phoenix, { LiveSocket }] = mods

		Promise.all(found).then((configs) => {
			const Hooks = configs.reduce((acc, { key: key, mod: mod }) => ({ ...acc, [key]: mod }), {})

			const liveSocket = new LiveSocket('/live', Phoenix.Socket, {
				hooks: Hooks,
				params: { _csrf_token: metaTagContent('csrf-token') },
			})

			liveSocket.connect()

			// expose liveSocket on window for web console debug logs and latency simulation:
			// window.liveSocket = liveSocket
			// liveSocket.enableDebug()
		})
	})
}
