const SELECTOR = 'button[data-clipboard-text]'

if (window.document.querySelectorAll(SELECTOR).length >= 1) {
	Promise.all([import('clipboard'), import('bootstrap/js/dist/tooltip'), import('jquery')]).then(
		([{ default: ClipboardJS }, { default: Tooltip }, { default: $ }]) => {
			$(SELECTOR).tooltip({
				trigger: 'manual',
				placement: 'auto',
				title: 'Copied to clipboard!',
				template:
					'<div class="tooltip font-size-sm font-family-sans-serif" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
			})

			const cpjs = new ClipboardJS(SELECTOR)
			const timers = new WeakMap()

			cpjs.on('success', (e) => {
				clearTimeout(timers.get(e.trigger))
				const $trigger = $(e.trigger)
				$trigger.tooltip('show')
				const timeout = setTimeout(() => {
					$trigger.tooltip('hide')
				}, 2000)
				timers.set(e.trigger, timeout)
			})

			cpjs.on('error', () => {
				Sentry.captureMessage('[ClipboardJS] failed')
			})
		},
	)
}
