import React from 'react'
import { createRoot } from 'react-dom/client'
import loader from 'js/loader'

const COMPONENTS = [
	{
		key: 'PhoneInput',
		loader: () => import('js/react/phone_input'),
	},
	{
		key: 'EmailsInput',
		loader: () => import('js/react/emails_input'),
	},
]

function findElementsForConfig(config) {
	return window.document.querySelectorAll(`[data-react-class="${config.key}"]`)
}

const found = loader(findElementsForConfig, COMPONENTS)

if (found.length >= 1) {
	found.forEach((configPromise) => {
		configPromise.then((config) => {
			console.debug(`Initializing react elements for ${config.key}`)
			if (typeof config.mod !== 'function') {
				console.error(config.mod)
				throw new Error(`failed to load module for ${config.key}`)
			}
			findElementsForConfig(config).forEach((el) => {
				const props = el.dataset.reactProps ? JSON.parse(el.dataset.reactProps) : {}
				const root = createRoot(el)
				root.render(<config.mod {...props} />)
			})
		})
	})
}
