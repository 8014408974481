// Sass
import 'css/app.scss'

// Error reporting
import Sentry from 'js/base/sentry'
window.Sentry = Sentry

// Polyfills
import 'url-search-params-polyfill'
import 'whatwg-fetch'

// Dependencies with side-effects
import 'phoenix_html'

import metaTagContent from 'js/base/meta_tag_content'
window.metaTagContent = metaTagContent

// Import dependencies
import 'popper.js'
import 'bootstrap/js/dist/button'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/tab'

import 'js/clipboard'
import 'js/liveview'
import 'js/react'
import 'js/stimulus'
import 'js/tooltip'
