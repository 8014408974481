import loader from 'js/loader'
import metaTagContent from 'js/base/meta_tag_content'

const CONTROLLERS = [
	{
		key: 'applicant-comments',
		loader: () => import('js/stimulus/applicant_comments'),
	},
	{
		key: 'applicant-status',
		loader: () => import('js/stimulus/applicant_status'),
	},
	{
		key: 'applicant-tags',
		loader: () => import('js/stimulus/applicant_tags'),
	},
	{
		key: 'captcha',
		loader: () => import('js/stimulus/captcha'),
		check: () => metaTagContent('public_captchas_enabled') === 'true',
	},
	{
		key: 'char-count',
		loader: () => import('js/stimulus/char_count'),
	},
	{
		key: 'temporal',
		loader: () => import('js/stimulus/temporal'),
	},
	{
		key: 'toggle',
		loader: () => import('js/stimulus/toggle'),
	},
]

const KEYS = {}
window.document.querySelectorAll(`[data-controller]`).forEach((node) => {
	node.dataset.controller.split(' ').forEach((name) => {
		KEYS[name] = true
	})
})

const found = loader((config) => KEYS[config.key] === true, CONTROLLERS)

if (found.length >= 1) {
	Promise.all([import('@stimulus/core'), ...found]).then(([{ Application }, ...configs]) => {
		const stimulus = new Application()
		configs.forEach((config) => stimulus.register(config.key, config.mod))
		stimulus.start()
	})
}
